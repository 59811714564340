import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import loader from './helpers/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorUI from './components/global/ErrorUI';

import ResourceState from './context/resource/resourceState';
import UserState from './context/user/userState';
import VaceState from './context/vace/vaceState';
import SocketState from './context/socket/socketState'

const Homepage = React.lazy(() => import('./pages/Home'));
const NetworkUI = React.lazy(() => import('./components/global/NetworkUI'));

// checkout
const CheckoutPage = React.lazy(() => import('./pages/checkout/Checkout'));
const InvoicePreview = React.lazy(() => import('./pages/checkout/InvoicePreview'));
const PaymentPage = React.lazy(() => import('./pages/payment/Pay'));
const VerifyPage = React.lazy(() => import('./pages/payment/Verify'));


const App = () => {

  const errorHandler = (err: any, info: any) => {
    console.log(err, 'logged error');
    console.log(info, 'logged error info');
  }

  return (

    <Router>

      <UserState>

        <SocketState>

          <VaceState>

            <ResourceState>

              <Suspense fallback={loader.MainLoader()}>

                <ErrorBoundary FallbackComponent={ErrorUI} onReset={() => { window.location.reload() }} onError={errorHandler}>

                  <Routes>

                    {/* All Routes */}
                    <Route path='/home' element={<Homepage />} />
                    <Route path='/' element={<Homepage />} />
                    <Route path='/no-network' element={<NetworkUI />} />
                    <Route path='/link/:label' element={<PaymentPage />} />
                    <Route path='/verify' element={<VerifyPage />} />
                    <Route path='/invoice/:code' element={<InvoicePreview />} />

                  </Routes>

                </ErrorBoundary>

              </Suspense>

            </ResourceState>

          </VaceState>

        </SocketState>

      </UserState>

    </Router>

  )

}

export default App;